@font-face {
  font-family: Helvetica;
  panose-1: 2 11 6 4 2 2 2 2 2 4;
}
@font-face {
  font-family: 'Cambria Math';
  panose-1: 2 4 5 3 5 4 6 3 2 4;
}
@font-face {
  font-family: Calibri;
  panose-1: 2 15 5 2 2 2 4 3 2 4;
}
@font-face {
  font-family: Roboto;
}
/* Style Definitions */
p.MsoNormal,
li.MsoNormal,
div.MsoNormal {
  margin-top: 0in;
  margin-right: 0in;
  margin-bottom: 8pt;
  margin-left: 0in;
  line-height: 107%;
  font-size: 11pt;
  font-family: 'Calibri', sans-serif;
}
.MsoChpDefault {
  font-family: 'Calibri', sans-serif;
}
.MsoPapDefault {
  margin-bottom: 8pt;
  line-height: 107%;
}
@page WordSection1 {
  size: 595.3pt 841.9pt;
  margin: 70.85pt 85.05pt 70.85pt 85.05pt;
}
div.WordSection1 {
  page: WordSection1;
}
