/*
    Use the DejaVu Sans font for displaying and embedding in the PDF file.
    The standard PDF fonts do not support Unicode characters.
*/
.fontPdf {
  font-family: "DejaVu Sans", "Arial", sans-serif;
  font-size: 12px;
}
.fontPdf div{
  font-family: "DejaVu Sans", "Arial", sans-serif;
  font-size: 12px;
}
.fontPdf h1{
  font-family: "DejaVu Sans", "Arial", sans-serif;
  font-size: 12px;
}
.fontPdf h2 {
  font-family: "DejaVu Sans", "Arial", sans-serif;
  font-size: 12px;
}
.fontPdf p {
  font-family: "DejaVu Sans", "Arial", sans-serif;
  font-size: 12px;
}

/*
  The example loads the DejaVu Sans from the Kendo UI CDN.
  Other fonts have to be hosted from your application.
  The official site of the Deja Vu Fonts project is
  https://dejavu-fonts.github.io/.
*/
@font-face {
  font-family: "DejaVu Sans";
  src: url("https://kendo.cdn.telerik.com/2023.1.117/styles/fonts/DejaVu/DejaVuSans.ttf") format("truetype");
}

@font-face {
  font-family: "DejaVu Sans";
  font-weight: bold;
  src: url("https://kendo.cdn.telerik.com/2023.1.117/styles/fonts/DejaVu/DejaVuSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "DejaVu Sans";
  font-style: italic;
  src: url("https://kendo.cdn.telerik.com/2023.1.117/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf") format("truetype");
}

@font-face {
  font-family: "DejaVu Sans";
  font-weight: bold;
  font-style: italic;
  src: url("https://kendo.cdn.telerik.com/2023.1.117/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf") format("truetype");
}


@font-face {
  font-family: "DejaVu Sans";
  src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.mtooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.mtooltip .mtooltiptext {
  visibility: hidden;
  width: 220px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  font-size: 14px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
}

.mtooltip:hover .mtooltiptext {
  visibility: visible;
}

@media (max-width: 992px) {
  .mtooltip .mtooltiptext {
    visibility: hidden;
    width: 220px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    font-size: 14px;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
  }
}
