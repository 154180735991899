@font-face {
  font-family: "DejaVu Sans";
  src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans.ttf") format("truetype");
}


.question {
  text-align: justify;
  margin-top: 50px;
  height: 100%;
}
.question h2 {
    font-size: 1.2rem;
    margin-top: 0.8rem !important;
    margin-bottom: 1rem !important;
}


.question h4 {
  font-size: 1.3rem;
  margin-top: 0.8rem !important;
  margin-bottom: 1rem !important;
}

.question .desc { 
  font-size: 1rem;
}

.question label {
  display: inline;
}
.g { 
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 5px 8px;
  padding: 20px 10px;
  display: flex;
  align-items: center;  
  justify-content: center;  
  
}

.g1 {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 5px 8px;
  padding: 20px 10px;
}

.g2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 5px 8px;
  padding: 20px 10px;
}

.g3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5px 8px;
  padding: 20px 10px;
}

.g4 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 5px 8px;
  padding: 20px 10px;
}

.question .btn { 
    max-width: 300px;
}
.actions .btn {
    width: 200px;
}
.actions {
    align-items: center;
    text-align: center;
}


/* .cabecalho {
  background-color: #FFAFAF;
}

.fimMedico {
  background-color: #f8e384;
} */

.acoes button.btn {
  min-width: 200px;
  margin: 5px;
}

.acoes .row div {
  align-items: center;
  text-align: center;
}
.desc   {
  margin-bottom: 50px;
}